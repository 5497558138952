import React from "react";

export const TextLoader = () => {
  return (
    <div className="w-full flex gap-2">
      <div class="h-1 bg-gray-300 rounded-full w-1/2"></div>
      <div class="h-1 bg-gray-300 rounded-full w-1/2"></div>
    </div>
  );
};
