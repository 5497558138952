import { React, useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import Modal from "react-modal";
import PoPopupList from "./PoPopupList";
import { useNavigate } from "react-router-dom";
import PoConfirmDelete from "./PoConfirmDelete";

const JobPoes = ({ job, setJob, formDisabled, setModified }) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
    },
  };
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [poDeleteModalIsOpen, setPoDeleteModelIsOpen] = useState(false);
  const [poTotal, setPoTotal] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setPoDeleteModelIsOpen(false);
  }
  const deleteSelectedRows = () => {
    // if (job.packages.length > 1) {
    setPoDeleteModelIsOpen(true);
    // confirmAlert({
    //   title: "Confirm to Delete",
    //   message: "Are you sure to do this.",
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: () => {
    //         deletePo();
    //       },
    //     },
    //     {
    //       label: "No",
    //     },
    //   ],
    // });
  };

  const updatePackageDetails = (poes) => {
    let total = {
      totalQuantity: 0,
      totalChWeight: 0,
      totalVolume: 0,
      totalWeight: 0,
    };
    poes
      .filter((x) => x.package)
      .map((po) => {
        total.totalQuantity = total.totalQuantity + parseInt(po.package.colli);
        total.totalWeight =
          total.totalWeight + parseInt(po.package.weight * po.package.colli);
        total.totalChWeight =
          total.totalChWeight +
          parseFloat(po.package?.chargableWeight * po.package.colli);
        total.totalVolume =
          total.totalVolume + parseFloat(po.package?.volume * po.package.colli);
      });
    setPoTotal(total);
  };

  useEffect(() => {
    if (job.poes && job.poes.length > 0) {
      updatePackageDetails(job.poes);
    }
  }, [job.poes]);

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedRows(job.poes.map((po) => po.package?.id));
    } else {
      setSelectedRows([]);
    }
  };

  const toggleRow = (id) => {
    if (selectedRows.includes(id)) {
      setSelectAll(false);
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const deletePo = (poes) => {
    setModified(true);
    setPoDeleteModelIsOpen(false);
    const updatedPoes = job.poes.filter(
      (po) => !selectedRows.includes(po.package?.id)
    );
    const updatedPackages = job.packages.filter(
      (pkg) => !selectedRows.includes(pkg.packageID)
    );
    setJob({
      ...job,
      poes: updatedPoes,
      packages: updatedPackages,
      removedPoes: poes.map((x) => ({
        poId: x.id,
        packageId: x.package.id,
        location: x.package.location,
        movementStatus: parseInt(x.package.movementStatus),
      })),
    });
    setSelectedRows([]);
    updatePackageDetails(updatedPoes);
    setSelectAll(false);
  };

  return (
    <div className="mt-5 px-5 w-full">
      <div className="flex ml-auto w-1/3 mb-2 items-center gap-2">
        <button
          type="submit"
          title="Import PO"
          className="inline-flex self-end disabled:opacity-30 w-full h-full bg-lime-600 px-2 py-1 gap-1 justify-center items-center border border-transparent shadow-sm text-xs rounded-md text-white font-bold"
          onClick={() => openModal()}
          disabled={formDisabled}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 6v12m6-6H6"
            />
          </svg>
          Import PO
        </button>
        <button
          type="submit"
          title="Delete Selected"
          className="inline-flex self-end disabled:opacity-30 w-full h-full bg-red-600 px-2 py-1 gap-1 justify-center items-center border border-transparent shadow-sm text-xs rounded-md text-white font-bold "
          onClick={deleteSelectedRows}
          disabled={selectedRows.length === 0 || formDisabled}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="white"
            class="w-5 h-5"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
          Delete Selected
        </button>
      </div>
      <div class="max-h-[65vh] border-b border-t bg-gray-300 p-0 border-gray-300 overflow-auto">
        <table class="w-full h-full text-xs text-left text-gray-500 border rounded-lg shadow-md sm:rounded-lg">
          <thead class="text-xxs sticky znone z-10 top-0 border-gray-300 text-gray-700 uppercase bg-gray-200 rounded-t-lg">
            <tr>
              <th scope="col" class="py-3 px-2">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={toggleSelectAll}
                  disabled={formDisabled}
                  className="rounded-sm focus:ring-0"
                />
              </th>
              <th scope="col" class="py-3 px-2">
                PO Number
              </th>
              <th scope="col" class="py-3 px-2">
                Vessel
              </th>
              <th scope="col" class="py-3 px-2">
                Supplier
              </th>
              <th scope="col" class="py-3 px-2">
                Location
              </th>
              <th scope="col" class="py-3 px-2">
                Boxes
              </th>
              <th scope="col" class="py-3 px-2">
                Ch.weight(KG)
              </th>
              <th scope="col" class="py-3 px-2">
                Volume(CBM)
              </th>
              <th scope="col" class="py-3 px-2">
                Weight(KG)
              </th>
              <th scope="col" class="py-3 px-2">
                LxWxH
              </th>
            </tr>
          </thead>
          <tbody className="max-h-40 min-h-fit overflow-y-scroll">
            {job.poes &&
              job.poes.map((v, pid) => {
                if (v.package) {
                  const isSelected = selectedRows.includes(v.package.id);
                  return (
                    <tr class="bg-white border-b h-full">
                      <td
                        scope="row"
                        class="py-2 px-2 font-medium text-gray-900 whitespace-nowrap"
                        key={pid}
                      >
                        <input
                          type="checkbox"
                          checked={isSelected}
                          onChange={() => toggleRow(v.package.id)}
                          className="rounded-sm focus:ring-0"
                          disabled={formDisabled}
                        />
                      </td>
                      <td
                        class="py-2 px-2 border-l text-cyan-500 underline cursor-pointer"
                        onClick={() => navigate(`/po/${v.id}`)}
                      >
                        {v.poNo}
                      </td>
                      <td class="py-2 px-2 border-l">{v.vesselName}</td>
                      <td class="py-2 px-2 border-l">{v.supplierName}</td>
                      <td class="py-2 px-2 border-l">{v.package?.location}</td>
                      <td class="py-2 px-2 border-l">{v.package?.colli}</td>
                      <td class="py-2 px-2 border-l">
                        {v.package?.chargableWeight?.toFixed(2)}
                      </td>
                      <td class="py-2 px-2 border-l">
                        {v.package?.volume?.toFixed(2)}
                      </td>
                      <td class="py-2 px-2 border-l">
                        {v.package?.weight?.toFixed(2)}
                      </td>
                      <td class="py-2 px-2 border-l">
                        {v.package?.length}X{v.package?.width}X
                        {v.package?.height}
                      </td>
                    </tr>
                  );
                }
              })}
            <tr className="text-xxs sticky znone bottom-0 bg-white text-gray-700 uppercase font-semibold">
              <td class="py-4 px-2"></td>
              <td class="py-1 px-2"></td>
              <td class="py-1 px-2"></td>
              <td class="py-4 px-2"></td>
              <td class="py-4 px-2">Total&nbsp;&nbsp;:</td>
              <td class="py-4 px-2">
                {poTotal?.totalQuantity}
                &nbsp;NOS
              </td>
              <td class="py-4 px-2">
                {poTotal?.totalChWeight.toFixed(3)}
                &nbsp;KG
              </td>
              <td class="py-4 px-2">
                {poTotal?.totalVolume.toFixed(3)}
                &nbsp;CBM
              </td>
              <td class="py-4 px-2">
                {poTotal?.totalWeight}
                &nbsp;KG
              </td>
              <td class="py-1 px-2"></td>
            </tr>
          </tbody>
        </table>
        <div className="w-full flex justify-end">
          <Modal
            isOpen={modalIsOpen}
            //onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <PoPopupList
              setJob={setJob}
              closeModal={closeModal}
              job={job}
              setModified={setModified}
            />
          </Modal>
          <Modal
            isOpen={poDeleteModalIsOpen}
            //onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <PoConfirmDelete
              data={job.poes.filter((x) =>
                selectedRows.includes(x.package?.id)
              )}
              onSave={deletePo}
              closeModal={closeModal}
            />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default JobPoes;
