import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../constants";
import { toast, ToastContainer } from "react-toastify";

const PasswordReset = () => {
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState();
  const [email, setEmail] = useState();

  const OnHandleChange = (e) => {
    setEmail(e.target.value);
  };
  const OnSubmit = async (e) => {
    setLoading(true);
    setDisable(true);
    e.preventDefault();
    try {
      const res = await axios.get(
        `${BASE_URL}/Auth/ResetPassword?email=${email}`
      );
      toast.success(
        "If email matches with our account, You will recieve a link to reset the password."
      );
      setLoading(false);
    } catch (error) {
      setDisable(false);
      setLoading(false);
      if (error.response?.status === 400) {
        toast.error(error.response.data);
      } else {
        toast.error("An error occured while sending Email!");
      }
    }
  };

  return (
    <div className="flex h-full w-full justify-center items-center">
      <div className="flex flex-col w-1/4">
        <div className="flex w-full justify-center">
          <img src="vml_logo.png" className=" h-40" />
        </div>
        <form className="flex flex-col justify-center" onSubmit={OnSubmit}>
          <div className="text-center text-sm font-semibold text-gray-900 mb-2">
            Reset your password
          </div>
          <div className="text-sm text-center mb-10">
            Enter your email and we'll send you a link to reset your password.
          </div>
          <div className="flex flex-col">
            <label for="email" className="text-sm font-semibold text-gray-900">
              Email Address
            </label>
            <input
              name="email"
              type="text"
              className="mt-2 appearance-none text-slate-900 bg-white rounded-md block w-full px-3 h-10 shadow-sm sm:text-sm focus:outline-none placeholder:text-slate-400 focus:ring-2 focus:ring-sky-500 ring-1 ring-slate-200"
              value={email}
              onChange={OnHandleChange}
            />
            <button
              type="submit"
              className="inline-flex justify-center rounded-lg text-sm font-semibold py-2.5 px-4 bg-slate-900 disabled:opacity-25 text-white hover:bg-slate-700 mt-6 w-full"
              disabled={disable}
            >
              {loading && (
                <svg class="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                  <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
              Reset Your Password
            </button>
          </div>
        </form>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </div>
  );
};

export default PasswordReset;
