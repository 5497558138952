import { useState, useEffect } from "react";
import { XIcon } from "@heroicons/react/outline";
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../../constants";
import SlidingPane from "react-sliding-pane";
import { ComboBox } from "@progress/kendo-react-dropdowns";

export default function WarehouseSlider({
  open,
  setOpen,
  selectedMaster,
  onUpdate,
  onCreate,
  setSelectedMaster,
}) {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [agents, setAgents] = useState([]);
  const [cities, setCities] = useState();

  useEffect(() => {
    const loadLookups = async () => {
      axios.get(`${BASE_URL}/Lookup/Countries?searchText=~ALL`).then((res) => {
        setCountries(res.data);
      });

      let res = await axios.get(
        `${BASE_URL}/Lookup/AllAgents?searchText=~ALL&take=10`
      );
      setAgents(res.data);
    };
    loadLookups();
  }, []);

  const handleCityChange = (e) => {
    formik.setFieldValue("city", e.target.value);
    formik.setFieldValue("cityId", e.value.id);
  };

  const formik = useFormik({
    initialValues: selectedMaster,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const selectedCountry = countries.find(
        (x) => x.code === values.countryCode
      );
      if (selectedCountry) {
        values.countryName = selectedCountry.name;
      }
      if (selectedMaster.id) {
        axios
          .put(`${BASE_URL}/warehouse`, values)
          .then((res) => {
            onUpdate(values);
            setLoading(false);
            toast.success("Warehouse Created Successfully");
            //setOpen(false);
          })
          .catch((error) => {
            toast.error("An error occured while saving the warehouse!");
            setLoading(false);
          });
      } else {
        axios
          .post(`${BASE_URL}/warehouse`, values)
          .then((res) => {
            if (res.status === 200) {
              setLoading(false);
              toast.success("Warehouse Created Successfully");
              onCreate(values, res.data);
              const data = { ...values, id: res.data };
              setSelectedMaster(data);
              console.log(data);
              //resetForm({ values: "" });
              //setOpen(false);
            }
          })
          .catch((error) => {
            if (error.response.status === 400) {
              toast.error(error.response.data);
            } else {
              toast.error("An error occured while saving the warehouse!");
            }
            setLoading(false);
          });
      }
      //resetForm();
    },
  });

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/Lookup/City?searchText=~ALL&countryCode=${formik.values.countryCode}`
      )
      .then((res) => {
        setCities(res.data);
      });
  }, [formik.values.countryCode]);

  const filterComboBoxData = (e) => {
    if (e.filter.value === "") {
      e.filter.value = "~ALL";
    }
    axios
      .get(
        `${BASE_URL}/Lookup/City?searchText=${e.filter.value}&countryCode=${formik.values.countryCode}`
      )
      .then((res) => {
        setCities(res.data);
      });
  };

  return (
    <SlidingPane
      closeIcon={
        <button
          type="button"
          className="rounded-md text-black hover:text-white focus:outline-none focus:ring-2 focus:ring-white absolute top-0"
        >
          <span className="sr-only">Close panel</span>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      }
      hideHeader
      isOpen={open}
      from="right"
      width="680px"
      className=""
    >
      <div className="absolute inset-0 overflow-hidden">
        <div className="w-screen max-w-2xl h-full">
          <form
            className="relative h-full flex flex-col bg-white shadow-xl"
            onSubmit={formik.handleSubmit}
          >
            <div className="flex-1 h-[75vh] overflow-y-scroll">
              {/* Header */}
              <div className="px-4 py-4 bg-[#e9f4ff] sm:px-6 sticky top-0">
                <div className="flex items-start justify-between space-x-3">
                  <div className="space-y-1 text-2xl">
                    {selectedMaster.id ? "Edit Warehouse" : "New Warehouse"}
                    <p className="text-sm text-gray-500">
                      # {selectedMaster.id ? "A" + selectedMaster.id : "New"}
                    </p>
                  </div>
                  <div className="h-7 flex items-center">
                    <button
                      type="button"
                      className="text-gray-400 hover:text-gray-500"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>

              {/* Divider container */}
              <div className="py-4 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                {/* Project name */}
                <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                  <div>
                    <label
                      htmlFor="name"
                      className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                      Name
                      <span className="block text-red-500 mt-0.5 pl-1">*</span>
                    </label>
                  </div>
                  <div className="sm:col-span-2">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      required
                      className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md uppercase"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                    />
                  </div>
                </div>

                <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-4">
                  <label
                    htmlFor="countryCode"
                    className="text-sm flex font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Country
                    <span className="block text-red-500 mt-0.5 pl-1">*</span>
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <select
                      id="countryCode"
                      name="countryCode"
                      className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      onChange={formik.handleChange}
                      value={formik.values.countryCode}
                    >
                      {countries.map((c) => {
                        return (
                          <option key={c.code} value={c.code} label={c.name} />
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                  <div>
                    <label
                      htmlFor="name"
                      className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                    >
                      City
                      <span className="block text-red-500 mt-0.5 pl-1">*</span>
                    </label>
                  </div>
                  <div className="mt-1 w-full col-span-2" id="compo">
                    <ComboBox
                      data={cities}
                      textField="name"
                      dataItemKey="id"
                      id="cityId"
                      name="city"
                      suggest={true}
                      clearButton={true}
                      filterable={true}
                      onFilterChange={filterComboBoxData}
                      className="block focus:ring-blue-500 focus:border-blue-500 w-full h-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      onChange={handleCityChange}
                      value={formik.values.city}
                    />
                  </div>
                </div>
                <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-4">
                  <label
                    htmlFor="agentId"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Agent
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <select
                      id="agentId"
                      name="agentId"
                      className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                      onChange={formik.handleChange}
                      value={formik.values.agentId}
                    >
                      <option selected value={null}>
                        Select Agent
                      </option>
                      {agents.map((agent) => {
                        return <option value={agent.id} label={agent.name} />;
                      })}
                    </select>
                  </div>
                </div>
                <fieldset>
                  <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-4">
                    <div>
                      <legend className="text-sm font-medium text-gray-900">
                        Consolidation Center
                      </legend>
                    </div>
                    <div className="space-y-5 sm:col-span-2">
                      <div className="space-y-5 sm:mt-0">
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              id="isConsolidationCenter"
                              name="isConsolidationCenter"
                              type="checkbox"
                              className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                              onChange={formik.handleChange}
                              defaultChecked={
                                formik.values.isConsolidationCenter
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset className="">
                  <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-4">
                    <div>
                      <legend className="text-sm font-medium text-gray-900">
                        Active
                      </legend>
                    </div>
                    <div className="space-y-5 sm:col-span-2">
                      <div className="space-y-5 sm:mt-0">
                        <div className="relative flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              id="active"
                              name="active"
                              type="checkbox"
                              className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                              onChange={formik.handleChange}
                              defaultChecked={formik.values.active}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>

            {/* Action buttons */}
            <div className=" w-full bg-white px-4 border-t border-gray-200 py-4 sm:px-6">
              <div className="space-x-3 flex justify-end">
                <button
                  type="button"
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                  disabled={
                    !(formik.isValid && formik.dirty) ||
                    formik.values.name === ""
                  }
                >
                  {loading && (
                    <svg
                      class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {selectedMaster.id ? "Update" : "Create"}
                </button>
              </div>
            </div>
          </form>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {/* Same as */}
          <ToastContainer />
        </div>
      </div>
    </SlidingPane>
  );
}
