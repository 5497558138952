import React, { useEffect, useState } from "react";
import { BASE_URL, movementStatuses } from "../../constants";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import axios from "axios";

const PoConfirmDelete = ({ data, onSave, closeModal }) => {
  const [tableData, setTableData] = useState(data);
  const [editIdx, setEditIdx] = useState(-1);
  const [editRow, setEditRow] = useState({});
  const [warehouses, setWarehouses] = useState([]);

  const handleEdit = (idx) => {
    setEditIdx(idx);
    setEditRow(tableData[idx]);
  };

  const handleChange = (e, packageId) => {
    const { value } = e.target;
    setTableData((pre) =>
      pre.map((x) =>
        x.package.id === packageId
          ? { ...x, package: { ...x.package, location: value.toUpperCase() } }
          : x
      )
    );
  };

  const handleSelectChange = (e, packageId) => {
    const { value, name } = e.target;
    console.log(value, packageId);
    let warehouse = null;
    if (name === "currentWarehouseId")
      warehouse = warehouses.find((x) => x.id == value);
    console.log(warehouse, warehouses, value);
    setTableData((pre) =>
      pre.map((x) =>
        x.package.id === packageId
          ? {
              ...x,
              package: {
                ...x.package,
                [name]: value,
                location:
                  name === "currentWarehouseId"
                    ? warehouse?.cityName
                    : x.package.location,
              },
            }
          : x
      )
    );
  };

  const handleSave = () => {
    const updatedData = [...tableData];
    updatedData[editIdx] = editRow;
    setTableData(updatedData);
    setEditIdx(-1);
  };

  const handleCancel = () => {
    setEditIdx(-1);
    setEditRow({});
  };

  const loadData = async () => {
    const res = await axios.get(
      `${BASE_URL}/Warehouse?searchText=~ALL&page=1&pageSize=10`
    );
    if (res.status === 200) {
      setWarehouses(res.data.data);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className=" mx-auto p-0 w-full">
      <div className="text-base font-medium capitalize py-2">Update Status</div>
      <table class="w-full h-full text-xs text-left text-gray-500 border rounded-lg shadow-sm sm:rounded-lg">
        <thead class="text-xxs sticky znone z-10 top-0 border-gray-300 text-gray-700 uppercase bg-gray-200 rounded-t-lg">
          <tr>
            <th scope="col" class="py-3 px-2">
              PO Number
            </th>
            <th scope="col" class="py-3 px-2">
              Ch.weight(KG)
            </th>
            <th scope="col" class="py-3 px-2">
              Volume(CBM)
            </th>
            <th scope="col" class="py-3 px-2">
              Weight(KG)
            </th>
            <th scope="col" class="py-3 px-2">
              LxWxH
            </th>
            <th scope="col" class="py-3 px-2">
              Warehouse
            </th>
            <th scope="col" class="py-3 px-2">
              Location
            </th>
            <th scope="col" class="py-3 px-2">
              Status
            </th>
          </tr>
        </thead>
        <tbody className="max-h-40 min-h-fit overflow-y-scroll">
          {tableData.map((v, idx) => {
            if (v.package) {
              return (
                <tr class="bg-white border-b h-full w-full">
                  <td
                    class="py-2 px-2 border-l text-cyan-500 underline cursor-pointer"
                    // onClick={() => navigate(`/po/${v.id}`)}
                  >
                    <a href={`#/po/${v.id}`} target="_blank" rel="noreferrer">
                      {v.poNo}
                    </a>
                  </td>
                  <td class="py-2 px-2 border-l">
                    {v.package?.chargableWeight?.toFixed(2)}
                  </td>
                  <td class="py-2 px-2 border-l">
                    {v.package?.volume?.toFixed(2)}
                  </td>
                  <td class="py-2 px-2 border-l">
                    {v.package?.weight?.toFixed(2)}
                  </td>
                  <td class="py-2 px-2 border-l">
                    {v.package?.length}X{v.package?.width}X{v.package?.height}
                  </td>
                  <td class="py-2 px-2 border-l w-full">
                    {v.package.currentWarehouseName}
                  </td>
                  <td class="py-2 px-2 border-l">
                    <input
                      value={v.package?.location}
                      onChange={(e) => handleChange(e, v.package.id)}
                      className="w-28 border-b-2 border-x-0 border-t-0 py-1 border-b-zinc-400 focus:outline-none px-1"
                    />
                  </td>
                  <td class="py-2 px-2 border-l">
                    <div className="mt-1 w-28 text-xs">
                      <select
                        value={v.package.movementStatus} // Set the value to the id directly
                        name="movementStatus"
                        onChange={(e) => handleSelectChange(e, v.package.id)}
                        className="border-b border-x-0 border-t-0 focus:ring-0 text-xs px-2 py-1 w-full"
                      >
                        {movementStatuses.map((option) => (
                          <option
                            key={option.id}
                            value={option.id}
                            className="py-2 px-2"
                          >
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
      <div className="w-full flex justify-end pt-4 items-center ">
        <div className="flex gap-2">
          <button
            type="button"
            className="inline-flex h-9 w-28 float-right justify-center py-2 px-4 border-2 border-sky-600 shadow-sm text-sm font-semibold rounded-md bg-sky-600 text-white focus:outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
            onClick={() => onSave(tableData)}
          >
            Apply
          </button>
          <button
            type="button"
            className="inline-flex h-9 w-28 float-right justify-center py-2 px-4 border-2 border-black shadow-sm text-sm font-semibold rounded-md bg-black text-white focus:outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
            onClick={() => closeModal()}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PoConfirmDelete;
