import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AppContext = React.createContext({
  token: "",
  isLoggedIn: false,
  login: (profile) => {},
  logout: () => {},
});

export const AppContextProvider = (props) => {
  const profileString = localStorage.getItem("profile");
  let initialProfile = null;
  if (profileString) initialProfile = JSON.parse(profileString);
  const [profile, setProfile] = useState(initialProfile);
  const userIsLoggedIn = !!profile?.token;
  if (userIsLoggedIn) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${profile.token}`;
  }

  const loginHandler = (userProfile) => {
    setProfile(userProfile);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${userProfile.token}`;
    localStorage.setItem("profile", JSON.stringify(userProfile));
  };

  const logoutHandler = () => {
    setProfile(null);
    localStorage.removeItem("profile");
  };

  const contextValue = {
    profile: profile,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AppContext.Provider value={contextValue}>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContext;
