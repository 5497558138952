import React, { useState } from "react";
import { movementStatuses } from "../../constants";

export const Package = ({ pack, packageSaved, packageDeleted, index }) => {
  const [currentPackage, setCurrentPackage] = useState(pack);
  const [visible, setVisible] = useState(true);

  const onValueChange = (e) => {
    setCurrentPackage((es) => ({ ...es, [e.target.name]: e.target.value }));
  };

  const onPackageSave = (cpkg, _) => {
    packageSaved(cpkg);
    setCurrentPackage({ ...cpkg, editMode: false, isNew: false });
  };

  const onEditClick = () => {
    setCurrentPackage({ ...currentPackage, editMode: true });
  };

  return (
    <li className="rounded-xl bg-white drop-shadow-sm p-4 border-l-4 border-sky-400 border-solid mt-2">
      <div className="flex align-center -ml-4">
        <div className="flex item-center w-full">
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              setVisible(!visible);
            }}
            className={visible ? "rotate-90 transition" : "transition"}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </a>
          {visible ? (
            <p className="font-semibold text-sm self-center text-gray-700 pl-3">
              Package {index + 1}
            </p>
          ) : (
            <p className="font-semibold mr-2 text-sm text-gray-500">
              {currentPackage.length}X{currentPackage.width}X
              {currentPackage.height}
            </p>
          )}
          {currentPackage?.editMode ? (
            <div className="ml-auto flex items-center w-1/3">
              <label
                htmlFor="length"
                className="font-normal flex mr-2 text-xs text-gray-500"
              >
                Qty <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                name="colli"
                value={currentPackage.colli}
                onChange={onValueChange}
                className="block w-full p-1 shadow-sm sm:font-normal text-xs text-gray-700 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
              />
            </div>
          ) : (
            <div className="ml-auto flex items-center w-1/4">
              <span className="font-normal text-sm text-gray-500">Qty : </span>
              <p className="font-normal text-md text-gray-700">
                {currentPackage.colli}
              </p>
            </div>
          )}
        </div>
        {!currentPackage?.editMode && (
          <div className="ml-auto flex">
            <button className="mr-2" onClick={onEditClick}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-gray-700"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
            </button>
            <button onClick={() => packageDeleted(currentPackage)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-gray-700"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </button>
          </div>
        )}
      </div>
      <div
        visible={visible}
        className={
          visible ? "collapsing in transition" : "collapsing transition"
        }
      >
        {currentPackage?.editMode ? (
          <form
            className="flex flex-wrap pt-2"
            onSubmit={onPackageSave.bind(this, currentPackage)}
          >
            <div className="w-4/12 p-2 pt-1 pb-1">
              <label
                htmlFor="length"
                className="font-normal text-xs text-gray-500"
              >
                Length <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="length"
                  value={currentPackage.length}
                  onChange={onValueChange}
                  required
                  className="block w-full p-1.5 shadow-sm sm:font-normal text-xs text-gray-700 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="w-4/12 p-2 pt-1 pb-1">
              <label
                htmlFor="width"
                className="font-normal text-xs text-gray-500"
              >
                Width <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="width"
                  value={currentPackage.width}
                  onChange={onValueChange}
                  required
                  className="block w-full p-1.5 shadow-sm sm:font-normal text-xs text-gray-700 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="w-4/12 p-2 pt-1 pb-1">
              <label
                htmlFor="height"
                className="font-normal text-xs text-gray-500"
              >
                Height <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="height"
                  value={currentPackage.height}
                  onChange={onValueChange}
                  required
                  className="block w-full p-1.5 shadow-sm sm:font-normal text-xs text-gray-700 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="w-4/12 p-2 pt-1 pb-1">
              <label
                htmlFor="city"
                className="font-normal text-xs text-gray-500"
              >
                weight <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="weight"
                  value={currentPackage.weight}
                  onChange={onValueChange}
                  className="block w-full p-1.5 shadow-sm sm:font-normal text-xs text-gray-700 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="w-4/12 p-2 pt-1 pb-1">
              <label
                htmlFor="volume"
                className="font-normal text-xs text-gray-500"
              >
                Volume
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="volume"
                  value={
                    (currentPackage.length *
                      currentPackage.width *
                      currentPackage.height) /
                    1000000
                  }
                  disabled
                  className="block w-full p-1.5 shadow-sm sm:font-normal text-xs text-gray-700 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="w-4/12 p-2 pt-1 pb-1">
              <label
                htmlFor="chweight"
                className="font-normal text-xs text-gray-500"
              >
                Ch. Weight
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="chweight"
                  value={(
                    (currentPackage.length *
                      currentPackage.width *
                      currentPackage.height) /
                    6000
                  ).toPrecision(2)}
                  disabled
                  className="block w-full p-1.5 shadow-sm sm:font-normal text-xs text-gray-700 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="w-full p-2 flex justify-end">
              <button
                type="button"
                className="mr-1 inline-flex justify-center py-2 px-4 border border-red-500 shadow-sm text-xs font-medium rounded-lg text-white bg-red-500 hover:bg-red-700"
                onClick={() => {
                  if (currentPackage.isNew === true) {
                    packageDeleted(currentPackage);
                  } else {
                    setCurrentPackage({
                      ...currentPackage,
                      editMode: false,
                    });
                  }
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-sky-400 shadow-sm text-xs font-medium rounded-lg text-white bg-sky-400 hover:bg-white hover:text-sky-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
              >
                Save
              </button>
            </div>
          </form>
        ) : (
          <div className="pt-2">
            <ul className="flex flex-wrap">
              <li className="w-4/12 p-2 pt-1 pb-1">
                <span className="font-normal text-xs text-gray-500">
                  Length
                </span>
                <p className="font-medium text-sm text-gray-700">
                  {currentPackage.length} cm
                </p>
              </li>
              <li className="w-4/12 p-2 pt-1 pb-1">
                <span className="font-normal text-xs text-gray-500">Width</span>
                <p className="font-medium text-sm text-gray-700">
                  {currentPackage.width} cm
                </p>
              </li>
              <li className="w-4/12 p-2 pt-1 pb-1">
                <span className="font-normal text-xs text-gray-500">
                  Height
                </span>
                <p className="font-medium text-sm text-gray-700">
                  {currentPackage.height} cm
                </p>
              </li>
              <li className="w-4/12 p-2 pt-1 pb-1">
                <span className="font-normal text-xs text-gray-500">
                  Weight
                </span>
                <p className="font-medium text-sm text-gray-700">
                  {currentPackage.weight} kg
                </p>
              </li>
              <li className="w-4/12 pb-2">
                <span className="font-normal text-xs text-gray-500">
                  Volume
                </span>
                <p className="font-medium text-sm text-gray-700">
                  {(
                    (currentPackage.length *
                      currentPackage.width *
                      currentPackage.height) /
                    1000000
                  ).toFixed(3)}{" "}
                  m3
                </p>
              </li>
              <li className="w-4/12 p-2 pt-1 pb-1">
                <span className="font-normal text-xs text-gray-500">
                  Ch. Weight
                </span>
                <p className="font-medium text-sm text-gray-700">
                  {(
                    (currentPackage.length *
                      currentPackage.width *
                      currentPackage.height) /
                    6000
                  ).toFixed(2)}{" "}
                  kg
                </p>
              </li>
              <li className="w-full p-2 pt-1 pb-1">
                <span className="font-normal text-xs text-gray-500">
                  Movement Status
                </span>
                <p className="font-medium text-sm text-gray-700">
                  {currentPackage.movementStatus &&
                    movementStatuses.find(
                      (x) => x.id == currentPackage.movementStatus
                    ).name}
                </p>
              </li>
            </ul>
          </div>
        )}
      </div>
    </li>
  );
};
