import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../../constants";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ContactList from "../ContactList";
import SlidingPane from "react-sliding-pane";

export default function CustomerSlider({
  open,
  setOpen,
  entity,
  onUpdate,
  onCreate,
}) {
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dirtyContact, setDirtyContact] = useState(false);

  useEffect(() => {
    axios.get(`${BASE_URL}/Lookup/Countries?searchText=~ALL`).then((res) => {
      setCountries(res.data);
    });
    axios.get(`${BASE_URL}/Lookup/Currencies`).then((res) => {
      setCurrencies(res.data);
    });
    axios.get(`${BASE_URL}/Lookup/Customers?searchText=~ALL`).then((res) => {
      setCustomers(res.data);
    });
  }, []);

  const contactCreatedHandler = (contact) => {
    entity.contacts = [...entity.contacts, contact];
    setDirtyContact(true);
  };

  const contactUpdatedHandler = (contact) => {
    entity.contacts = entity.contacts.map((ec, index) =>
      index === contact.index ? { ...contact } : ec
    );
    setDirtyContact(true);
  };

  const contactedDeletedHandler = (contact) => {
    let index = entity.contacts.indexOf(contact);
    entity.contacts.splice(index, 1);
    setDirtyContact(true);
  };

  const formik = useFormik({
    initialValues: entity,
    enableReinitialize: true,
    onSubmit: (updatedEntity) => {
      setLoading(true);
      // update contact
      updatedEntity.contacts = entity.contacts
        ? entity.contacts.map((x) => ({ ...x, id: 0 }))
        : [];
      if (entity.id) {
        axios
          .put(`${BASE_URL}/Customer`, updatedEntity)
          .then((res) => {
            onUpdate(updatedEntity);
            setLoading(false);
            toast.success("Customer Details Updated Successfully");
            //setOpen(false);
          })
          .catch((error) => {
            toast.error("An error occured while saving the customer!");
            setLoading(false);
          });
      } else {
        axios
          .post(`${BASE_URL}/Customer`, updatedEntity)
          .then((res) => {
            onCreate(updatedEntity);
            setLoading(false);
            toast.success("Customer Created Successfully");
            setOpen(false);
          })
          .catch((error) => {
            toast.error("An error occured while saving the customer!");
            setLoading(false);
          });
      }
    },
  });
  return (
    <div>
      <SlidingPane
        closeIcon={
          <button
            type="button"
            className="rounded-md text-black hover:text-white focus:outline-none focus:ring-2 focus:ring-white absolute top-0"
          >
            <span className="sr-only">Close panel</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        }
        hideHeader
        isOpen={open}
        from="right"
        width="680px"
        className=""
      >
        <div className="relative inset-0 overflow-hidden">
          <div className="w-screen max-w-2xl bg-white shadow-xl">
            <div className="px-4 py-4 bg-[#e9f4ff] sm:px-6 ">
              <div className="flex items-start justify-between space-x-3 ">
                <div className="space-y-1 text-2xl">
                  {entity.id ? "Edit Customer" : "New Customer"}
                </div>
                <div className="h-7 flex items-center">
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <Tabs>
              <TabList>
                <Tab>General</Tab>
                <Tab>Contacts</Tab>
              </TabList>
              <TabPanel>
                <form
                  className=" flex flex-col h-[75vh] pb-5 overflow-y-scroll"
                  // onSubmit={formik.handleSubmit}
                >
                  <div className="flex-1">
                    <div className="py-4 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                        <div>
                          <label
                            htmlFor="name"
                            className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Name
                            <span className="block text-red-500 mt-0.5 pl-1">
                              *
                            </span>
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            required
                            className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md uppercase"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                          />
                        </div>
                      </div>

                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                        <div>
                          <label
                            htmlFor="address"
                            className="flex text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Address
                            <span className="block text-red-500 mt-0.5 pl-1">
                              *
                            </span>
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <textarea
                            id="address"
                            name="address"
                            required
                            rows={3}
                            className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md uppercase"
                            onChange={formik.handleChange}
                            value={formik.values.address}
                          />
                        </div>
                      </div>

                      <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-4">
                        <label
                          htmlFor="countryCode"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Country
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <select
                            id="countryCode"
                            name="countryCode"
                            className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            onChange={formik.handleChange}
                            value={formik.values.countryCode}
                          >
                            {countries.map((c) => {
                              return (
                                <option
                                  key={c.code}
                                  value={c.code}
                                  label={c.name}
                                />
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-4">
                        <label
                          htmlFor="billingCurrencyCode"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Currency
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <select
                            id="billingCurrencyCode"
                            name="billingCurrencyCode"
                            className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            onChange={formik.handleChange}
                            value={formik.values.billingCurrencyCode}
                          >
                            {currencies.map((currency) => {
                              return (
                                <option
                                  key={currency.code}
                                  value={currency.code}
                                  label={currency.code}
                                />
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-4">
                        <label
                          htmlFor="groupId"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Customer Group
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <select
                            id="groupId"
                            name="groupId"
                            className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                            onChange={formik.handleChange}
                            value={formik.values.groupId}
                          >
                            <option value={null}>Select a customer</option>
                            {customers.map((customer) => {
                              return (
                                <option
                                  key={customer.id}
                                  value={customer.id}
                                  label={customer.name}
                                />
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                        <div>
                          <label
                            htmlFor="billingInstructions"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Billing Instructions
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <textarea
                            id="billingInstructions"
                            name="billingInstructions"
                            rows={5}
                            className="block w-full shadow-sm sm:text-xs focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md uppercase"
                            onChange={formik.handleChange}
                            value={formik.values.billingInstructions}
                          />
                        </div>
                      </div>
                      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-4">
                        <div>
                          <label
                            htmlFor="remarks"
                            className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                          >
                            Remarks
                          </label>
                        </div>
                        <div className="sm:col-span-2">
                          <textarea
                            id="remarks"
                            name="remarks"
                            rows={3}
                            className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md uppercase"
                            onChange={formik.handleChange}
                            value={formik.values.remarks}
                          />
                        </div>
                      </div>
                      <fieldset>
                        <div className="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:px-6 sm:py-4">
                          <div>
                            <legend className="text-sm font-medium text-gray-900">
                              Active
                            </legend>
                          </div>
                          <div className="space-y-5 sm:col-span-2">
                            <div className="space-y-5 sm:mt-0">
                              <div className="relative flex items-start">
                                <div className="flex items-center h-5">
                                  <input
                                    id="active"
                                    name="active"
                                    type="checkbox"
                                    className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                    onChange={formik.handleChange}
                                    defaultChecked={formik.values.active}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </TabPanel>
              <TabPanel>
                <div className="space-y-1 pl-4 sm:space-y-0">
                  <ContactList
                    contacts={entity.contacts}
                    onContactCreated={contactCreatedHandler}
                    onContactedUpdated={contactUpdatedHandler}
                    onContactedDeleted={contactedDeletedHandler}
                  />
                </div>
              </TabPanel>
              <div className="fixed bottom-0 w-full bg-white flex-shrink-0 px-4 border-t border-gray-200 py-4 sm:px-6">
                <div className="space-x-3 flex justify-end">
                  <button
                    type="button"
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                    disabled={
                      (!(formik.isValid && formik.dirty) && !dirtyContact) ||
                      formik.values.name === ""
                    }
                    onClick={formik.handleSubmit}
                  >
                    {loading && (
                      <svg
                        class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    {entity.id ? "Update" : "Create"}
                  </button>
                </div>
              </div>
            </Tabs>

            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <ToastContainer />
          </div>
        </div>
      </SlidingPane>
    </div>
  );
}
