import { React, useState, useEffect } from "react";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../constants";
import { toast } from "react-toastify";
import { filterBy } from "@progress/kendo-data-query";

const JobCard = ({ jobType, selectedState, setOpen }) => {
  const defaultJob = {
    customer: "",
    vesselId: "",
    customerId: 0,
    mot: 100,
    pos: [],
  };
  const navigate = useNavigate();
  const [formValidate, setFormValidate] = useState({
    defferentLocation: false,
  });
  const [firstPLocation, setfirstPLocation] = useState();
  const [job, setJob] = useState(defaultJob);
  const [filteredBillingParty, setFilteredBillingParty] = useState();

  const onFilterChange = async (e) => {
    let res = await axios.get(
      `${BASE_URL}/Lookup/Customers?searchText=${e.filter.value}`
    );
    setFilteredBillingParty(res.data);
  };

  const handleChange = (e) => {
    setJob({ ...job, [e.target.name]: e.target.value });
  };

  const onBillingPartySelected = (e) => {
    setJob({
      ...job,
      customer: e.value,
      currentId: e.value.id,
    });
  };

  const onDeletePo = (e, currentId, poIndex) => {
    setJob({
      ...job,
      pos: job.pos.map((po, index) =>
        poIndex === index
          ? {
              ...po,
              packages: po.packages.filter((_, index) => currentId !== index),
            }
          : po
      ),
    });
    setfirstPLocation();
    //similerLocationCheck();
  };

  const OnSubmit = async (e) => {
    const submitPos = [];
    e.currentTarget.disabled = true;
    job.pos.map((e) => {
      return e.packages.map((p) => {
        let selectedPackages = { cargoId: e.id, packageId: p.id };
        submitPos.push(selectedPackages);
      });
    });
    const payLoad = {
      jobType: job.jobType,
      customerId: job.customerId,
      vesselId: job.vesselId,
      mot: job.mot,
      packages: submitPos,
      vesselId: job.vesselId,
    };
    const res = await axios.post(`${BASE_URL}/Job`, payLoad);
    if (res.status === 200) {
      toast.success("JOB created successfully!");
      navigate(`/job/${res.data}`);
    } else {
      toast.error("An error occured while creating PO!");
    }
  };

  const loadData = async () => {
    const res = await axios.get(`${BASE_URL}/Lookup/Customers?searchText=~ALL`);
    setFilteredBillingParty(res.data);
  };

  const onCancelClick = () => {
    setJob(defaultJob);
    setOpen(false);
  };

  useEffect(() => {
    loadData();
    const firstPo = selectedState[0];
    setJob({
      ...job,
      customer: { id: firstPo.customerId, name: firstPo.customerName },
      customerId: firstPo.customerId,
      vesselName: firstPo.vesselName,
      vesselId: firstPo.vesselId,
      jobType:
        jobType === 2 ? 100 : jobType === 3 ? 200 : jobType === 4 ? 300 : 400,
      pos: selectedState,
    });
  }, []);

  useEffect(() => {
    const firstPackageLocation = job.pos[0]?.packages[0]?.location;
    setFormValidate({
      ...formValidate,
      defferentLocation: job.pos.some((po) =>
        po?.packages.some((p) => p.location !== firstPackageLocation)
      ),
    });
    setfirstPLocation(job.pos[0]?.packages[0]?.location);
  }, [job.pos]);

  return (
    <div className="pointer-events-auto w-screen max-w-md">
      <div className="flex h-full flex-col overflow-y-scroll bg-white pb-6 shadow-xl">
        <div className="flex-col justify-center w-96 h-[80vh] pl-6 pr-4">
          {job.pos &&
            job.pos.map((po) => {
              return po.packages.map((p) =>
                p.activeJobId != null && p.jobStatus === "Active" ? (
                  <div className="w-full px-4 py-3 border bg-red-100 border-red-300 rounded-lg mb-1 text-xxs">
                    Selected Package {p.length}X{p.width}X{p.height} PO No :
                    {po.poNo} is already in a Active Job
                  </div>
                ) : null
              );
            })}
          {formValidate?.defferentLocation ? (
            <div className="w-full px-4 py-3 border bg-red-100 border-red-300 rounded-lg mb-1 text-xxs">
              please select packages that has same location
            </div>
          ) : null}
          {jobType !== 1 &&
          job.pos.some(
            (po) => po.warehouseId === null || po.warehouseId === ""
          ) ? (
            <div className="w-full px-4 py-3 border bg-red-100 border-red-300 rounded-lg mb-1 text-xxs">
              Selected Pos Warehouse should not be Empty
            </div>
          ) : null}
          <div
            className={`w-full px-4 py-3 border ${
              jobType === 1
                ? "bg-green-100 border-green-300"
                : jobType === 2
                ? "bg-sky-100 border-sky-300"
                : jobType === 3
                ? "bg-orange-100 border-amber-300"
                : jobType === 4 && "bg-purple-100 border-purple-300"
            } rounded-lg mb-1`}
          >
            <div className="flex gap-3">
              <div
                className={`flex rounded-full h-14 w-14 justify-center items-center ${
                  jobType === 1
                    ? "bg-green-300"
                    : jobType === 2
                    ? "bg-sky-300"
                    : jobType === 3
                    ? "bg-amber-200"
                    : jobType === 4 && "bg-purple-200"
                }`}
              >
                <img
                  src={`${
                    jobType === 1
                      ? "truck.svg"
                      : jobType === 2
                      ? "truck.svg"
                      : jobType === 3
                      ? "wh-vessel.svg"
                      : jobType === 4 && "wh-destination.svg"
                  }`}
                  className=""
                  alt="wh-wh"
                />
              </div>
              <div className="flex-col pt-1 text-zinc-800">
                <div className="text-sm font-medium">
                  {jobType === 1
                    ? "Supplier - WH"
                    : jobType === 2
                    ? "WH - WH"
                    : jobType === 3
                    ? "WH - Vessel"
                    : jobType === 4 && "WH - Destination"}
                </div>
                <div className="text-xs font-normal">
                  {jobType === 1
                    ? "Supplier - WH"
                    : jobType === 2
                    ? "Warehouse to warehouse"
                    : jobType === 3
                    ? "WH to Vessel (Port / Deck)"
                    : jobType === 4 && "Warehouse to Destination"}
                </div>
              </div>
            </div>
            <div className="flex-col mt-5">
              <div className="mb-2">
                <label htmlFor="billingParty" className="font-normal text-sm">
                  Customer
                </label>
                <div
                  className="rounded-md text-xs h-8 mt-1 bg-white"
                  id="compo"
                >
                  <ComboBox
                    data={filteredBillingParty}
                    dataItemKey="id"
                    textField="name"
                    name="Customer"
                    className="h-full bg-inherit"
                    placeholder="Customer"
                    suggest={true}
                    clearButton={true}
                    filterable={true}
                    onFilterChange={onFilterChange}
                    value={job.customer}
                    onChange={onBillingPartySelected}
                  />
                </div>
              </div>
              <div>
                <label htmlFor="vessel" className="font-normal text-sm">
                  Vessel
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="Vessel"
                    className="py-2 px-3 h-8 w-full p-0 rounded-md bg-zinc-100 border-zinc-300 text-neutral-400 font-normal text-xs pointer-events-none"
                    placeholder="AAL Bangkok-982391"
                    value={job.vesselName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex-col mt-2 w-full">
                <label htmlFor="shipmentType" className="font-normal text-sm">
                  MOT
                </label>
                <div class="inline-flex rounded group w-full mt-1 h-8 border-sky-600 border">
                  <button
                    type="button"
                    className={`bg-white text-xs font-medium text-center w-1/5 rounded-tl rounded-bl ${
                      job.mot === 100 ? "bg-sky-600 text-white" : "text-black"
                    }`}
                    onClick={() => setJob({ ...job, mot: 100 })}
                  >
                    Air
                  </button>
                  <button
                    type="button"
                    className={`bg-white text-xs font-medium w-1/5 text-center ${
                      job.mot === 200 ? "bg-sky-600 text-white" : "text-black"
                    }`}
                    onClick={() => setJob({ ...job, mot: 200 })}
                  >
                    Sea
                  </button>
                  <button
                    type="button"
                    className={`bg-white text-xs font-medium text-center w-1/5 ${
                      job.mot === 300 ? "bg-sky-600 text-white" : "text-black"
                    }`}
                    onClick={() => setJob({ ...job, mot: 300 })}
                  >
                    Road
                  </button>
                  <button
                    type="button"
                    className={`bg-white text-xs font-medium w-1/5 text-center ${
                      job.mot === 400 ? "bg-sky-600 text-white" : "text-black"
                    }`}
                    onClick={() => setJob({ ...job, mot: 400 })}
                  >
                    Courier
                  </button>
                  <button
                    type="button"
                    className={`bg-white text-xs font-medium w-1/5 text-center rounded-tr rounded-br ${
                      job.mot === 500 ? "bg-sky-600 text-white" : "text-black"
                    }`}
                    onClick={() => setJob({ ...job, mot: 500 })}
                  >
                    Others
                  </button>
                </div>
              </div>
              <hr className="border-[0.5px] border-collapse border-[#88DBFF] mt-6" />
              <div className="flex-col px-2 py-2">
                <div className="flex justify-between items-center">
                  <div className="flex gap-2">
                    <label className="text-sm font-medium">
                      selected Packages
                    </label>
                    <label className="bg-[#FF0707] text-white h-5 w-7 rounded-[36px] font-medium text-[10px] flex justify-center items-center">
                      {job.pos.reduce(
                        (total, p) => total + p.packages.length,
                        0
                      )}
                    </label>
                  </div>
                </div>
                <div className="mt-4 grid grid-cols-1 overflow-y-scroll max-h-44 gap-2 py-2">
                  {job.pos.map((id, poId) => {
                    return id.packages.map((p, pid) => {
                      return (
                        <span
                          id="badge-dismiss-one"
                          className="inline-flex items-center gap-4 w-auto border border-zinc-300 h-9 justify-between px-5 text-xxs font-medium text-black bg-white rounded-2xl"
                          key={pid}
                        >
                          <div>{id.poNo}</div>
                          <div>
                            {p.length}X{p.width}X{p.height}
                          </div>
                          <div>{p.colli}</div>
                          <div className="flex items-center gap-1 w-20">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-3 h-3"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                              />
                            </svg>
                            {p.location}
                          </div>
                          <button
                            type="button"
                            className="inline-flex items-center text-sm text-blue-400 bg-transparent rounded-sm"
                            data-dismiss-target="#badge-dismiss-one"
                            aria-label="Remove"
                            onClick={(e) => onDeletePo(e, pid, poId)}
                          >
                            <svg
                              aria-hidden="true"
                              className="w-3.5 h-3.5"
                              fill="black"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span className="sr-only">Remove badge</span>
                          </button>
                        </span>
                      );
                    });
                  })}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end mt-5 gap-2">
              <button
                type="button"
                className="bg-white flex h-9 w-20 border border-black rounded text-black text-xs font-semibold text-center justify-center items-center gap-2"
                onClick={onCancelClick}
              >
                Cancel
              </button>
              <button
                type="button"
                className={`flex h-9 w-32 border rounded text-white text-xs font-semibold text-center justify-center items-center gap-2 disabled:opacity-40 ${
                  jobType === 1
                    ? "bg-green-600 border-green-300"
                    : jobType === 2
                    ? "bg-sky-600 border-sky-300"
                    : jobType === 3
                    ? "bg-amber-400 border-amber-300"
                    : jobType === 4 && "bg-violet-500 border-purple-300"
                }`}
                onClick={OnSubmit}
                disabled={
                  job.pos.some((po) =>
                    po.packages.some(
                      (p) => p?.activeJobId !== null && p.jobStatus === "Active"
                    )
                  ) ||
                  (jobType !== 1 &&
                    job.pos.some(
                      (po) => po.warehouseId === null || po.warehouseId === ""
                    )) ||
                  job.pos.some((po) =>
                    po?.packages.some((p) => p.location !== firstPLocation)
                  ) ||
                  job.pos.reduce((total, p) => total + p.packages.length, 0) ===
                    0
                }
              >
                Create Job
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobCard;
