import { React, useEffect } from "react";

const JobCardList = ({ onProceedClick }) => {
  const JobCardDetails = [
    // {
    //   id: 1,
    //   title: "Origin-WH",
    //   image:"truck.svg",
    //   description: "Origin to warehouse",
    //   points: [
    //     "Shipment from Origin to Airport/ Port of DestinationOnly (not VML Agent)",
    //     "Shipment delivery from Origin to Local Agent/ Local Delivery Address",
    //     "Shipment release from Origin to Agents",
    //   ],
    // },
    {
      id: 2,
      title: "WH-WH",
      description: "Warehouse to warehouse",
      image: "truck.svg",
      points: [
        "Shipment from Origin/ Dest WH to Airport/ Port of DestinationOnly (not VML Agent)",
        "Shipment delivery from VML WH to Local Agent/ Local Delivery Address",
        "Shipment release from VML WH to Agents",
      ],
    },
    {
      id: 3,
      title: "WH-Vessel",
      image: "wh-vessel.svg",
      description: "WH to Vessel (Port / Deck)",
      points: [
        "Shipment from Origin/ Dest WH to Vessel",
        "Shipment from Origin/ Dest WH to Barge",
      ],
    },
    {
      id: 4,
      title: "WH - Destination",
      image: "wh-destination.svg",
      description: "Warehouse to Destination",
      points: [
        "Shipment from VML Hub/ Agent’s Origin WH to Destination W",
        "Shipment from VML Hub/ Agent’s Origin WH to Agent’s Destination W",
      ],
    },
  ];
  // const onProceedClick = (e) => {
  //   const id = e.target.id;
  //   setJobType(id);
  //   setIsShowing(false);
  // };

  // useEffect(() => {
  //   console.log(jobType, "ty");
  //   if (jobType !== 0) {
  //     setIsShowing(false);
  //   }
  // }, [jobType]);
  return (
    <div className="pointer-events-auto w-screen max-w-md">
      <div className="flex h-[88vh] w-96 flex-col overflow-y-scroll transition-all bg-white pb-3">
        <div className="flex-col justify-center w-full h-full pl-6 pr-4">
          {JobCardDetails.map((j) => (
            <div
              className={`h-fit w-full ${
                j.id === 1
                  ? "bg-green-100 border-green-300"
                  : j.id === 2
                  ? "bg-sky-100 border-sky-300"
                  : j.id === 3
                  ? "bg-orange-100 border-amber-200"
                  : j.id === 4 && "bg-purple-100 border-purple-300"
              } px-4 py-3 border rounded-lg mb-1`}
              key={j.id}
            >
              <div className="flex gap-3">
                <div
                  className={`flex rounded-full h-14 w-14 ${
                    j.id === 1
                      ? "bg-green-300"
                      : j.id === 2
                      ? "bg-sky-300"
                      : j.id === 3
                      ? "bg-orange-300"
                      : j.id === 4 && "bg-purple-300"
                  } justify-center items-center`}
                >
                  <img src={j.image} className="" alt="wh-wh" />
                </div>
                <div className="flex-col pt-1 text-[#333333]">
                  <div className="text-sm font-medium">{j.title}</div>
                  <div className="text-xs font-normal">{j.description}</div>
                </div>
              </div>
              <div className="flex-col mt-5 ml-7">
                <ul className="flex-col text-[9px] list-disc text-[#666666]">
                  {j.points.map((p, index) => (
                    <li className=" pb-1" key={index}>
                      {p}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex items-center justify-center mt-5">
                <button
                  type="button"
                  id={j.id}
                  onClick={(e) => onProceedClick(e, j.id)}
                  className={`${
                    j.id === 1
                      ? "bg-green-500 border-green-500"
                      : j.id === 2
                      ? "bg-sky-500 border-sky-500"
                      : j.id === 3
                      ? "bg-orange-400 border-amber-400"
                      : j.id === 4 && "bg-purple-500 border-purple-500"
                  } flex h-9 w-32 border  rounded text-white text-xs font-semibold text-center justify-center items-center gap-2`}
                >
                  Proceed
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="white"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default JobCardList;
