import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL, documentTypes } from "../../constants";
import LoadingPanel from "../job/JobLoader";
import AppContext from "../../context/app-context";

export const DocumentForm = ({
  documents,
  documentUploaded,
  documentDeleted,
  formDisabled,
}) => {
  const authCtx = useContext(AppContext);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (documents) {
      setSelectedFiles(
        documents.map((d) => ({
          ...d,
          documentTypeObject: documentTypes.find(
            (x) => x.id === d.documentTypeId
          ),
        }))
      );
    }
  }, [documents]);

  const onDocTypeChange = (e, docId) => {
    setSelectedFiles(
      selectedFiles.map((f) =>
        f.documentID === docId
          ? { ...f, documentTypeId: e.value.id, documentTypeObject: e.value }
          : f
      )
    );
  };

  const onCheckBoxChange = (e, docId) => {
    console.log(e);
    setSelectedFiles(
      selectedFiles.map((f) =>
        f.documentID === docId ? { ...f, showInPortal: e.target.checked } : f
      )
    );
  };

  const downloadFile = async (file) => {
    try {
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const response = await fetch(
        `${BASE_URL}/Storage/documents/download?id=${file.documentID}`,
        {
          config,
          method: "GET",
          headers: {
            Authorization: `Bearer ${authCtx.profile.token}`, // Replace with your actual access token
            // You may need to set other headers depending on your API requirements
          },
        }
      );
      if (response.ok) {
        console.log(response);
        const blob = await response.blob();

        // // Create a download link element
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = file.fileName; // Set the desired filename
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error fetching blob content:", error);
    }
  };

  const onSaveDocType = async () => {
    const newdocs = selectedFiles.map((f) => ({
      id: f.documentID,
      documentTypeId: f.documentTypeId,
      showInPortal: f.showInPortal,
      otherDocType: f.otherDocType,
    }));
    const res = await axios.post(
      `${BASE_URL}/storage/documents/updatedoctype`,
      newdocs
    );
    if (res.status === 200) toast.success("Document updated successfully!");
    else toast.error("An error occured while updating the document");
  };

  const changeHandler = async (event) => {
    setLoader(true);
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    const formData = new FormData();
    formData.append("files", event.target.files[0]);
    const res = await axios.post(
      `${BASE_URL}/storage/documents/upload`,
      formData,
      config
    );
    if (res.status === 200) {
      // save cargo
      setLoader(false);
      toast.success("Document uploaded successfully!");
      documentUploaded(res.data);
      const procFiles = res.data.map((f) => {
        return { documentID: f.id, fileName: f.fileName, size: f.size };
      });
      setSelectedFiles([...selectedFiles, ...procFiles]);
    } else {
      setLoader(false);
      toast.error("some error occured when document uploading!");
    }
  };

  const deleteDoc = async (docId) => {
    const res = await axios.post(
      `${BASE_URL}/storage/documents/delete?id=${docId}`
    );
    if (res.status === 200) {
      toast.success("Document deleted successfully!");
      const exDocIndex = selectedFiles.findIndex((x) => x.documentID === docId);
      selectedFiles.splice(exDocIndex, 1);
      documentDeleted(docId);
    } else toast.error("An error occured while deleting the document");
  };

  return (
    <div className="xl:w-full p-5 md:w-11/12 sm:w-3/6 xs:w-3/6 pt-5 h-[75vh]">
      <p className="font-medium text-lg text-black">Documents</p>
      <form className="space-y-8 divide-y divide-gray-200 h-full">
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="mt-3.5 grid grid-cols-1 gap-y-7 gap-x-4 sm:grid-cols-6 sm:gap-y-5">
            <div className="sm:col-span-8 flex flex-col">
              <label
                htmlFor="document"
                className="block text-sm font-normal text-gray-900"
              ></label>
              <div className="mt-1 w-full flex h-full">
                <div className="mx-6 w-1/3">
                  <div className="relative flex flex-col items-center justify-center h-80 border-2 border-gray-400 border-dotted">
                    <input
                      type="file"
                      className="absolute documents cursor-pointer h-full w-full border-0 focus:outline-none opacity-0"
                      name="file"
                      onChange={changeHandler}
                      disabled={formDisabled}
                    />
                    <img
                      className="h-10 w-auto mb-4"
                      src="upload.svg"
                      alt="Upload"
                    />
                    <h6 className="text-sm font-medium text-zinc-600 mb-1">
                      Attach Documents
                    </h6>
                    <p className="text-xs font-normal text-gray-400">
                      Drag Documents here
                    </p>
                  </div>
                </div>
                {loader && (
                  <div className="k-loading-mask">
                    <span className="k-loading-text">Loading</span>
                    <div className="k-loading-image" />
                    <div className="k-loading-color" />
                  </div>
                )}
                <div className="mt-2 ml-1 flex flex-col w-2/3 h-[65vh] overflow-hidden">
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 h-full">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:pr-8 h-full">
                      <div className="overflow-auto w-fit h-[50vh] pb-5">
                        <table className="min-w-full">
                          <tbody className="">
                            {selectedFiles &&
                              selectedFiles.map((file, fileIdx) => (
                                <tr
                                  key={file.documentID}
                                  className="border-b border-dashed border-gray-400"
                                >
                                  <td className="w-12 whitespace-nowrap py-3 pl-2 pr-1 text-sm font-medium text-gray-900">
                                    <img
                                      className="h-6 w-auto mb-4"
                                      src="pdf.svg"
                                      alt="pdf"
                                    />
                                  </td>
                                  <td
                                    className="max-w-[15vw] text-ellipsis truncate py-3 pr-1 text-sm font-medium text-gray-900 h-fit"
                                    title={file.fileName}
                                  >
                                    {file.fileName}
                                    <p className="text-sm text-gray-500 font-normal">
                                      {file.size}KB
                                    </p>
                                  </td>
                                  <td
                                    colSpan={file.documentTypeId === 8 ? 3 : 1}
                                    className="whitespace-nowrap w-full xl:min-w-full min-w-max px-3 flex flex-col gap-2 py-3 text-sm text-gray-500 h-full"
                                  >
                                    <div id="compo" className="h-8 w-fit">
                                      <ComboBox
                                        data={documentTypes}
                                        name="documentType"
                                        textField="name"
                                        className="sm:text-sm focus:ring-0 focus:border-0 py-2 px-3 h-full p-0"
                                        dataItemKey="id"
                                        suggest={true}
                                        clearButton={true}
                                        placeholder="Document Type"
                                        value={file.documentTypeObject}
                                        onChange={(e) =>
                                          onDocTypeChange(e, file.documentID)
                                        }
                                        disabled={formDisabled}
                                      />
                                    </div>
                                    {file.documentTypeId === 9 && (
                                      <div className="h-8">
                                        <input
                                          type="text"
                                          name="otherDocType"
                                          className="h-full w-full block shadow-sm sm:text-sm text-xs placeholder:text-xs font-normal text-neutral-700 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                                          placeholder="Specify The Document type"
                                          value={file.otherDocType}
                                          onChange={(e) =>
                                            setSelectedFiles(
                                              selectedFiles.map((f) =>
                                                f.documentID === file.documentID
                                                  ? {
                                                      ...f,
                                                      otherDocType:
                                                        e.target.value,
                                                    }
                                                  : f
                                              )
                                            )
                                          }
                                          disabled={formDisabled}
                                        />
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    <div
                                      class={`${
                                        file.documentTypeId === 8
                                          ? "w-full"
                                          : "w-24"
                                      } flex flex-col`}
                                    >
                                      <div>
                                        <input
                                          type="checkbox"
                                          id="showInPortal"
                                          name="showInPortal"
                                          value={file.showInPortal}
                                          checked={file.showInPortal}
                                          onClick={(e) =>
                                            onCheckBoxChange(e, file.documentID)
                                          }
                                          class="peer h-3 w-3"
                                          required
                                          disabled={formDisabled}
                                        />
                                        <label
                                          for="hosting-small"
                                          class="ml-2 text-xxs items-center w-full text-gray-500 bg-white cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600"
                                        >
                                          Show in Portal
                                        </label>
                                      </div>
                                      {/* {file.documentTypeId === 8 && (
                                        <ActionColumn
                                          file={file}
                                          deleteDoc={deleteDoc}
                                        />
                                      )} */}
                                    </div>
                                  </td>

                                  {/* {file.documentTypeId !== 8 && ( */}
                                  <td className="relative flex h-full items-center justify-center mt-1/2 pl-1 pr-2 text-right text-sm font-medium sm:pr-6">
                                    <div className="flex">
                                      <button
                                        className="ml-2 inline-flex items-center border border-transparent text-xs font-medium rounded shadow-sm text-whit focus:outline-none focus:ring-2 focus:ring-offset-2"
                                        //href={`${BASE_URL}/storage/documents/download?id=${file.documentID}`}
                                        //href={`${BASE_URL}/storage/documents/download?id=${file.documentID}`}
                                        type="button"
                                        disabled={formDisabled}
                                        onClick={() => downloadFile(file)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-6 w-6"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                          />
                                        </svg>
                                      </button>

                                      <button
                                        type="button"
                                        className="ml-2 inline-flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2"
                                        onClick={() =>
                                          deleteDoc(file.documentID)
                                        }
                                        disabled={formDisabled}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-6 w-6"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </td>
                                  {/* )} */}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      {selectedFiles.length !== 0 && (
                        <div className="sm:w-32 mt-7 ml-auto">
                          <button
                            type="button"
                            className="inline-flex w-full justify-center py-2 px-4 border border-lime-700 bg-lime-700 border-transparent shadow-sm text-sm font-medium rounded-md text-white hover:bg-lime-900 hover:border-lime-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none" // disabled={
                            onClick={onSaveDocType}
                            disabled={formDisabled}
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
