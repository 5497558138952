import React from "react";

const GraphLoader = () => {
  return (
    <div
      role="status"
      class="max-w-full h-full p-5 rounded animate-pulse dark:border-gray-700"
    >
      <div className="border border-gray-100 h-full space-x-8 items-end flex">
        <div class="w-full bg-gray-300 rounded-t-lg h-5/6"></div>
        <div class="w-full h-1/2 bg-gray-300 rounded-t-lg"></div>
        <div class="w-full bg-gray-300 rounded-t-lg h-3/5"></div>
        <div class="w-full h-4/5 bg-gray-300 rounded-t-lg"></div>
        <div class="w-full bg-gray-300 rounded-t-lg h-2/3"></div>
        <div class="w-full bg-gray-300 rounded-t-lg h-3/4"></div>
        <div class="w-full bg-gray-300 rounded-t-lg h-3/5"></div>
        <div class="w-full bg-gray-300 rounded-t-lg h-3/5"></div>
      </div>
      <span class="sr-only">Loading...</span>
    </div>
  );
};

export default GraphLoader;
