import React from "react";

const ListLoader = () => {
  return (
    <div role="status" class="max-w-md p-4 space-y-4 rounded animate-pulse">
      <div class="flex items-center justify-between">
        <div class="h-2.5 bg-gray-300 rounded-full w-24"></div>
        <div class="h-2.5 bg-gray-300 rounded-full w-12"></div>
      </div>
      <div class="flex items-center justify-between">
        <div class="h-2.5 bg-gray-300 rounded-full w-24"></div>
        <div class="h-2.5 bg-gray-300 rounded-full w-12"></div>
      </div>
      <div class="flex items-center justify-between">
        <div class="h-2.5 bg-gray-300 rounded-full w-24"></div>
        <div class="h-2.5 bg-gray-300 rounded-full w-12"></div>
      </div>
      <div class="flex items-center justify-between">
        <div class="h-2.5 bg-gray-300 rounded-full w-24"></div>
        <div class="h-2.5 bg-gray-300 rounded-full w-12"></div>
      </div>
      <div class="flex items-center justify-between">
        <div class="h-2.5 bg-gray-300 rounded-full w-24"></div>
        <div class="h-2.5 bg-gray-300 rounded-full w-12"></div>
      </div>
      <div class="flex items-center justify-between">
        <div class="h-2.5 bg-gray-300 rounded-full w-24"></div>
        <div class="h-2.5 bg-gray-300 rounded-full w-12"></div>
      </div>
      <span class="sr-only">Loading...</span>
    </div>
  );
};

export default ListLoader;
