import React, { useContext, useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axios from "axios";
import "./PoDetails.scss";
import { BASE_URL, CargoPickupTypes, poStatus } from "../../constants";
import { formatDate } from "@telerik/kendo-intl";
import { PoForm } from "./PoForm";
import { ShipmentForm } from "./ShipmentForm";
import { PackageList } from "./PackageList";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { DocumentForm } from "./DocumentForm";
import { UserIcon, CalendarIcon } from "@heroicons/react/solid";
import { confirmAlert } from "react-confirm-alert";
import AppContext from "../../context/app-context";
import { Tooltip } from "@progress/kendo-react-tooltip";
import Modal from "react-modal";
import KendoLoader from "../KendoLoader";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
  },
};
const defaultPo = {
  poNo: "",
  vesselId: "",
  customerId: "",
  customerName: "",
  supplierId: "",
  totalValue: 0.0,
  receivedDate: null,
  currency: { code: "USD" },
  cargoType: 100,
  shipmentInfo: { modeOfTransport: 100 },
  documents: [],
  packages: [],
  status: "Active",
  cancelDescription: "",
  isApproved: true,
};
const TooltipContentTemplate = (props) => {
  return (
    <a className="font-medium p-3">
      <span className="">{props.title}</span>
    </a>
  );
};
export default function PoDetails({ job, closeModal }) {
  const authCtx = useContext(AppContext);
  let navigate = useNavigate();
  let { id } = useParams();
  const [po, setPo] = useState(defaultPo);
  const [docState, setDocState] = useState(-1);
  const [modified, setModified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataLoad, setDataLoad] = useState(false);
  const [open, setOpen] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    savePo();
  };

  const savePo = async () => {
    console.log(po.receivedDate);
    if (id && id !== "new") {
      if (!po.id) po.id = id;
      setLoading(true);
      let res = await axios.put(`${BASE_URL}/cargo`, po);
      if (res.status === 200) {
        toast.success("PO updated successfully!");
        setModified(false);
        setLoading(false);
      } else {
        toast.error("An error occured while updating PO!");
        setLoading(false);
      }
    } else {
      setLoading(true);
      try {
        const res = await axios.post(`${BASE_URL}/cargo`, po);
        if (res.status === 200) {
          toast.success("PO created successfully!");
          setModified(false);
          setLoading(false);
          navigate(`/po/${res.data}`);
        }
      } catch (e) {
        if (e.response.status === 400) {
          toast.error(e.response.data);
        } else {
          toast.error("An error occured while creating PO!");
        }
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    // new po
    const loadPo = async () => {
      setDataLoad(true);
      if (id !== "new") {
        const res = await axios.get(`${BASE_URL}/cargo/${id}`);
        setPo({
          ...res.data,
          creationDate: res.data.creationDate
            ? new Date(res.data.creationDate)
            : null,
          receivedDate: res.data.receivedDate
            ? new Date(res.data.receivedDate)
            : null,
          vessel: { id: res.data.vesselId, name: res.data.vesselName },
          supplier: { id: res.data.supplierId, name: res.data.supplierName },
          warehouse: { id: res.data.warehouseId, name: res.data.warehouseName },
          currency: { code: res.data.currencyCode },
          coo: { code: res.data.cooCode, name: res.data.cooName },
          cargoPickupTypeObject: CargoPickupTypes.find(
            (x) => x.id === res.data.cargoPickupType
          ),
          shipmentInfo: {
            ...res.data.shipmentInfo,
            originAgent: {
              id: res.data.shipmentInfo?.originAgentId,
              name: res.data.shipmentInfo?.originAgentName,
            },
            detinationAgent: {
              id: res.data.shipmentInfo?.detinationAgentId,
              name: res.data.shipmentInfo?.detinationAgentName,
            },
          },
          status: res.data.status === null ? "Active" : res.data.status,
          // cancelDescription:
          //   res.data.cancelDescription === null
          //     ? ""
          //     : res.data.cancelDescription,
        });
      } else {
        if (authCtx.profile.role === 300)
          setPo({
            ...po,
            isApproved: false,
            warehouse: authCtx.profile.warehouse,
            warehouseId: authCtx.profile.warehouse.id,
            agentId: authCtx.profile.warehouse.agentId,
            agentName: authCtx.profile.warehouse.agentName,
            warehouseCity: authCtx.profile.warehouse.cityName,
          });
      }
      setDataLoad(false);
    };

    loadPo();
  }, []);

  useEffect(() => {
    if (docState >= 0) savePo(po);
  }, [docState]);

  const onDocumentUploaded = (newDocs) => {
    const cargoDocs = newDocs.map((doc) => {
      return {
        documentID: doc.id,
        cargoID: po.id,
        fileName: doc.fileName,
        size: doc.size,
      };
    });
    setPo({ ...po, documents: [...po.documents, ...cargoDocs] });
    setDocState(docState + 1);
  };

  const onDocumentDeleted = (docId) => {
    const exDocIndex = po.documents.findIndex((x) => x.documentID === docId);
    po.documents.splice(exDocIndex, 1);
    setPo({ ...po, documents: [...po.documents] });
    savePo(po);
  };

  const onPackageAdded = (newPkg) => {
    po.packages.push(newPkg);
  };

  const handleChange = (e) => {
    setPo({
      ...po,
      [e.target.name]: e.target.value,
    });
  };
  const onStatusChange = (i) => {
    if (i === "Active") {
      setOpen(true);
    } else {
      confirmAlert({
        title: "Confirm to Activate",
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              setModified(true);
              setPo({ ...po, status: "Active", cancelDescription: "" });
            },
          },
          {
            label: "No",
          },
        ],
      });
    }
  };

  const onCancelClick = () => {
    if (modified === true) {
      confirmAlert({
        title: "Discard Changes",
        message: "You have unsaved changes.Do you want to Continue.",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              if (!job) navigate(`/poes/all`);
              else closeModal();
            },
          },
          {
            label: "No",
          },
        ],
      });
    } else {
      if (!job) navigate(`/poes/all`);
      else closeModal();
    }
  };
  return (
    <div className="min-h-full h-full relative px-5 py-8 bg-white">
      {dataLoad && <KendoLoader />}
      <div
        className={`${
          po.status === "Active" ? "bg-sky-200" : "bg-orange-200"
        } flex justify-between items-center rounded-lg h-10 px-4 mr-2`}
      >
        <div className="flex font-medium">
          <div className="text-sm ml-1 text-slate-800">
            Po Number :&nbsp;&nbsp;
            <label className="text-orange-600">
              {id !== "new" ? po.poNo : "New PO"}
            </label>
          </div>
        </div>

        <div className="flex text-xxs font-medium items-center">
          <div className="flex items-center justify-between gap-2 mr-4">
            {/* <input
              type="checkbox"
              name="isApproved"
              className="w-4 h-4 text-green-600 bg-gray-100 focus:outline-0 ring-0 rounded focus:ring-0 dark:focus:ring-green-600"
              checked={po.isApproved}
              value={po.isApproved}
              onChange={() =>
                setPo((pre) => ({ ...pre, isApproved: !pre.isApproved }))
              }
              disabled={authCtx.profile.role === 300}
            /> */}
            <span
              className={`text-sm ${
                po.isApproved ? "text-green-600" : "text-orange-600"
              } `}
            >
              {po.isApproved ? "Approved" : "Not Approved"}
            </span>
          </div>
          <div className="flex items-center text-sm text-gray-500">
            <UserIcon
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-sky-600"
              aria-hidden="true"
            />
          </div>
          <div className="text-slate-800">
            Created By :&nbsp;
            <label className="text-neutral-700">
              {id !== "new" ? po.createdUserName : authCtx.profile.displayname}
            </label>
          </div>
          <hr className=" rotate-90 w-5 border-slate-200"></hr>
          <div className=" flex items-center text-sm text-gray-500">
            <CalendarIcon
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-sky-600"
              aria-hidden="true"
            />
          </div>
          <div className="text-slate-800">
            Created On :&nbsp;
            <label className="text-neutral-700">
              {id !== "new"
                ? formatDate(new Date(po.creationDate), "yyyy-MM-dd")
                : formatDate(new Date(), "yyyy-MM-dd")}
            </label>
          </div>
          <hr className=" rotate-90 w-5 border-slate-200"></hr>
          <div className="mr-1">
            {po.status === "Canceled" && (
              <Tooltip
                content={(props) => (
                  <TooltipContentTemplate title={props.title} />
                )}
                openDelay={50}
                position="bottom"
                anchorElement="target"
              >
                <strong
                  style={{
                    textAlign: "center",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="red"
                    class="w-4 h-4"
                    title={po.cancelDescription}
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                    />
                  </svg>
                </strong>
              </Tooltip>
            )}
          </div>
          <div className="text-slate-800">
            Status :&nbsp;
            <label
              className={`${
                po.status === "Active" ? "text-green-700" : "text-orange-700"
              } font-medium`}
            >
              {po.status}
            </label>
          </div>
        </div>
      </div>

      <Tabs className="po-tabs ">
        <div className="w-full sticky top-7 flex justify-between items-center pt-3 mb-2">
          <div></div>
          <TabList className="po-tab border-0 w-1/3 flex justify-center items-center">
            <Tab className="po-tab font-medium text-base bg-transparent text-gray-500 my-2 mx-3">
              Details
            </Tab>
            {/* <Tab className="po-tab font-medium text-base bg-transparent text-gray-500 my-2 mx-3.5">
              Shipment Info
            </Tab> */}
            {id && (
              <Tab className="po-tab font-medium text-base bg-transparent text-gray-500 my-2 mx-3.5">
                Documents
              </Tab>
            )}
          </TabList>
          <div className="w-fit flex gap-2 h-full mr-2">
            {!po.isApproved && authCtx.profile.role !== 300 && (
              <div className="sm:w-32">
                <button
                  type="button"
                  className="ml-4 inline-flex float-right w-full justify-center py-2 px-4 border border-green-500 shadow-sm text-sm font-semibold rounded-md text-white bg-green-500 hover:bg-green-700 hover:text-white focus:outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none" // disabled={
                  onClick={() => setPo((pre) => ({ ...pre, isApproved: true }))}
                >
                  Approve
                </button>
              </div>
            )}
            <div className="sm:w-32">
              <button
                type="button"
                className="inline-flex w-full disabled:opacity-50 disabled:cursor-not-allowed justify-center py-2 px-4 border border-lime-700 bg-lime-700 border-transparent shadow-sm text-sm font-semibold rounded-md text-white hover:bg-lime-900 hover:border-lime-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" // disabled={
                onClick={onSubmit}
                disabled={
                  !po.packages.length > 0 ||
                  po.poNo === "" ||
                  po.vesselId === "" ||
                  po.customerId === "" ||
                  po.supplierId === ""
                }
              >
                {loading && (
                  <svg
                    aria-hidden="true"
                    role="status"
                    class="mr-2 inline h-5 w-5 animate-spin text-green-400"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#FFF"
                    ></path>
                  </svg>
                )}
                Save
              </button>
            </div>
            <div className="sm:w-32">
              <button
                type="button"
                className="ml-4 inline-flex float-right w-full justify-center py-2 px-4 border border-black shadow-sm text-sm font-semibold rounded-md text-white bg-black hover:bg-black hover:text-white focus:outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none" // disabled={
                onClick={onCancelClick}
              >
                Close
              </button>
            </div>
            <div className="relative flex gap-1 items-center">
              {/* {po.status === "Active" ? ( */}
              <button
                id="dropdownDefault"
                data-dropdown-toggle="dropdown"
                class={`${
                  po.status === "Canceled"
                    ? "bg-green-500 border-green-600"
                    : "bg-red-500 border-red-600"
                } flex h-9 rounded-md border-2 text-white w-28 font-semibold text-sm justify-center items-center gap-2 cursor-pointer`}
                type="button"
                onClick={() => onStatusChange(po.status)}
              >
                {po.status === "Active" ? "Cancel" : "Activate"}
              </button>

              {/* {show && (
                <div class="z-50 w-28 h-fit overflow-hidden border border-gray-200 absolute top-9 divide-y divide-gray-300 origin-top-right right-0 mt-2 rounded-md shadow-2xl py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <ul
                    class="py-1 text-sm text-gray-700"
                    aria-labelledby="dropdownDefault"
                  >
                    {poStatus.map((i) => (
                      <li key={i.id}>
                        <button
                          id={i.id}
                          type="submit"
                          class="hover:bg-gray-200 px-4 py-3 w-full text-left text-sm text-gray-700 border-b flex items-center gap-3 disabled:cursor-not-allowed"
                          onClick={() => onStatusChange(i)}
                        >
                          {i.name}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )} */}
            </div>
          </div>
        </div>

        <TabPanel>
          <div className="flex flex-col mb-5">
            <div className="flex">
              <PoForm
                po={po}
                setPo={setPo}
                submit={onSubmit}
                setModified={setModified}
              ></PoForm>
              <div className="xl:w-1/4 px-2 border-l-2 md:w-2/5 sm:w-3/6 xs:w-3/6">
                <PackageList
                  po={po}
                  setPo={setPo}
                  packageAdded={onPackageAdded}
                  id={id}
                />
              </div>
            </div>
          </div>
          <Modal
            isOpen={open}
            //onAfterOpen={afterOpenModal}
            onRequestClose={() => setOpen(false)}
            style={customStyles}
            contentLabel="Example Modal"
            ariaHideApp={false}
          >
            <div className="flex flex-col px-3 py-5 w-[30vw] overflow-hidden rounded-lg shadow-[0_0_4px_rgba(0,0,0,0.1)] border border-slate-300 mt-3">
              <h1>Cancel PO</h1>
              <textarea
                rows={3}
                name="cancelDescription"
                placeholder="Please provide a reason for cancelling the purchase order"
                id="cancelDescription"
                value={po.cancelDescription}
                onChange={handleChange}
                className="block w-full h-40 shadow-sm sm:text-sm text-xs font-normal text-neutral-700 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
              />
              <div className="w-full mt-4 flex justify-end">
                <div className="flex gap-2">
                  <button
                    type="button"
                    className="inline-flex h-9 w-28 float-right justify-center py-2 px-4 border-2 border-sky-600 shadow-sm text-sm font-semibold rounded-md bg-sky-600 text-white focus:outline-none disabled:opacity-50"
                    onClick={() => {
                      setModified(true);
                      setPo({
                        ...po,
                        status: "Canceled",
                      });
                      setOpen(false);
                    }}
                    disabled={po.cancelDescription === ""}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="inline-flex h-9 w-28 float-right justify-center py-2 px-4 border-2 border-black shadow-sm text-sm font-semibold rounded-md bg-black text-white focus:outline-none disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </TabPanel>
        {/* <TabPanel>
          <ShipmentForm po={po} setPo={setPo} submit={onSubmit}></ShipmentForm>
        </TabPanel> */}
        {id && (
          <TabPanel>
            <DocumentForm
              documents={po.documents}
              documentUploaded={onDocumentUploaded}
              documentDeleted={onDocumentDeleted}
            ></DocumentForm>
          </TabPanel>
        )}
      </Tabs>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </div>
  );
}
