import React, { useEffect, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { process } from "@progress/kendo-data-query";
import { formatDate } from "@telerik/kendo-intl";
import { useNavigate } from "react-router-dom";
import { CustomColumnMenu } from "../CollumnMenu";
import { JobLoader } from "./JobLoader";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { BASE_URL } from "../../constants";
import axios from "axios";
import { toast } from "react-toastify";
import KendoLoader from "../KendoLoader";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { debounce } from "lodash";

const KendoDateCell = (props) => {
  const value = props.dataItem[props.field];
  return (
    <td className=" bg-inherit">
      {formatDate(new Date(value), "dd-MMM-yyyy")}
    </td>
  );
};

const ActiveCell = (props) => {
  const value = props.dataItem.jobStatus;
  return (
    <td>
      <div
        className={`${
          value === "Active"
            ? "bg-sky-500 border-sky-600"
            : value === "Closed"
            ? "bg-green-500 border-green-600"
            : value === "Canceled"
            ? "bg-red-500 border-red-600"
            : value === "Checked" && "bg-orange-500 border-orange-600"
        } w-16 rounded-xl text-white text-xxs font-semibold h-5 flex justify-center items-center`}
      >
        {value}
      </div>
    </td>
  );
};

function ProductNameCell(props) {
  return (
    <td title={props.dataItem[props.field]}>{props.dataItem[props.field]}</td>
  );
}

function ProductNameHeader(props) {
  return (
    <a className="k-link" onClick={props.onClick}>
      <span title={props.title}>{props.title}</span>
      {props.children}
    </a>
  );
}

const columns = [
  {
    field: "jobNo",
    title: "Job No",
    show: true,
    width: 130,
    filterable: true,
    sortable: true,
  },
  {
    field: "vesselName",
    title: "Vessel",
    show: true,
    width: "auto",
    filterable: true,
    cell: ProductNameCell,
    ProductNameHeader: ProductNameHeader,
  },
  {
    field: "customerName",
    title: "Customer",
    show: true,
    width: "auto",
    filterable: true,
    cell: ProductNameCell,
    ProductNameHeader: ProductNameHeader,
  },
  {
    field: "consigneeName",
    title: "Consignee",
    show: true,
    width: "auto",
    filterable: false,
    sortable: false,
    cell: ProductNameCell,
    ProductNameHeader: ProductNameHeader,
  },
  {
    field: "shipperName",
    title: "Shipper",
    show: true,
    width: "auto",
    filterable: false,
    sortable: false,
    cell: ProductNameCell,
    ProductNameHeader: ProductNameHeader,
  },
  {
    field: "awb",
    title: "AWB/ B/L",
    show: true,
    width: 135,
    filterable: false,
    sortable: false,
  },
  // {
  //   field: "destinationWhName",
  //   title: "WH Destination",
  //   show: true,
  //   width: 80,
  //   filterable: false,
  //   sortable: false,
  // },
  {
    field: "originPortName",
    title: "Origin Port",
    show: true,
    width: "auto",
    filterable: false,
    sortable: false,
    cell: ProductNameCell,
    ProductNameHeader: ProductNameHeader,
  },
  {
    field: "destinationPortName",
    title: "Destination Port",
    show: true,
    width: "auto",
    filterable: false,
    sortable: false,
    cell: ProductNameCell,
    ProductNameHeader: ProductNameHeader,
  },
  {
    field: "creationDate",
    title: "Creation Date",
    show: true,
    width: "auto",
    filterable: false,
    sortable: false,
    cell: KendoDateCell,
    ProductNameHeader: ProductNameHeader,
  },
  {
    field: "jobStatus",
    title: "Status",
    show: true,
    width: "auto",
    filterable: false,
    sortable: false,
    cell: ActiveCell,
  },
];

export default function JobList() {
  const defaultFilter = {
    vessel: null,
    customer: null,
    jobNo: "",
    awb: "",
    page: 0,
    pageSize: 11,
    total: 0,
  };
  const defaultState = {
    sort: [{ field: "creationDate", dir: "desc" }],
    skip: 0,
    take: 11,
  };
  let navigate = useNavigate();
  const _export = React.useRef(null);
  const [jobs, setJobs] = useState([]);
  const [vessels, setVessels] = useState();
  const [filteredVessels, setFilteredVessels] = useState();
  const [customers, setCustomers] = useState();
  const [filteredCustomers, setFilteredCustomers] = useState();
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState(defaultFilter);
  const [pageState, setPageState] = useState({
    sort: [{ field: "creationDate", dir: "desc" }],
    skip: 0,
    take: 11,
  });

  const [stateColumns, setStateColumns] = React.useState(columns);
  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };

  const onEditClick = (e) => {
    const editedItem = e.dataItem;
    navigate(`/job/${editedItem.id}`);
  };

  const handleGridDataStateChange = (e) => {
    setPageState(e.dataState);
  };

  const dataReceived = (result) => {
    setJobs(result.data.data);
    setFilterData({ ...filterData, total: result.data.totalCount });
  };

  const onAwbChange = async (e) => {
    setFilterData({ ...filterData, awb: e.target.value });
  };

  const onJobNoChange = async (e) => {
    setFilterData({ ...filterData, jobNo: e.target.value.toUpperCase() });
  };

  const onCustomerChange = async (e) => {
    setFilterData({
      ...filterData,
      customer: e.value,
      customerId: e.value ? e.value.id : null,
    });
  };

  const onVesselChange = async (e) => {
    setFilterData({
      ...filterData,
      vessel: e.value,
      vesselId: e.value ? e.value.id : null,
    });
  };

  const onJobFilter = async (filter) => {
    setLoading(true);
    const res = await axios.post(`${BASE_URL}/Job/GetJobFilter`, filter);
    if (res.status === 200) {
      if (res.data.legth !== 0) {
        dataReceived(res);
      } else {
        toast.error("No Data Found");
      }
    } else {
      toast.error("Error occured while filtering Data");
    }
    setLoading(false);
  };

  const onResetClick = () => {
    setFilterData(defaultFilter);
    onJobFilter(defaultFilter);
  };

  const loadData = async () => {
    let res = await axios.get(
      `${BASE_URL}/vessel?searchText=~ALL&page=1&pageSize=20`
    );
    setVessels(res.data.data);
    setFilteredVessels(res.data.data);
    res = await axios.get(`${BASE_URL}/Lookup/Customers?searchText=~All`);
    setCustomers(res.data);
    setFilteredCustomers(res.data);
  };

  const onPageChange = (e) => {
    console.log(e);
    const state = { ...pageState, skip: e.page.skip, take: e.page.take };
    setPageState(state);
    setFilterData({ ...filterData, page: state.skip, pageSize: state.take });
  };

  const filterList = debounce(async (e) => {
    let res;
    let searchText;
    if (e.filter.value === "") searchText = "~ALL";
    else searchText = e.filter.value;
    switch (e.target.name) {
      case "customer":
        if (searchText !== "") {
          res = customers.filter((x) =>
            x.name.toLowerCase().replace(/ /g, "").startsWith(searchText)
          );
          setFilteredCustomers(res.data);
        } else setFilteredCustomers(customers);
        break;
      case "vessel":
        console.log(searchText)
        if (searchText !== "") {
          res = await axios.get(
            `${BASE_URL}/vessel?searchText=${searchText}&page=0&pageSize=20`
          );
          setVessels(res.data.data);
        } else setFilteredVessels(vessels);
        break;
      default:
        break;
    }
  }, 300);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="mx-4 my-4 sm:mx-6 lg:mx-8">
      <div className="flex justify-between mb-4">
        <div className="flex justify-between w-full min-w-0">
          <div className="flex gap-1">
            <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">
              Jobs
            </h1>
            <div>
              <div className="dropdown inline-block relative">
                <div className="flex justify-center gap-2 items-center w-14 h-6 text-center p-1 bg-zinc-300 rounded-xl text-sm font-medium ml-2">
                  <div className="text-xs text-zinc-500">All</div>
                  <span className="mr-1 text-xs">{filterData.total}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-2">
            <div className="flex text-xs text-gray-900 font-medium items-center gap-2">
              <label>Job No</label>
              <input
                type="text"
                name="jobNo"
                pattern="[A-Z]*"
                className="w-36 h-7 rounded-md shadow-sm  border border-slate-200 text-neutral-400 text-sm font-normal"
                value={filterData?.jobNo}
                onChange={(e) => onJobNoChange(e)}
              />
            </div>
            <div className="flex text-xs text-gray-900 font-medium items-center gap-2">
              <label>AWB</label>
              <input
                type="text"
                name="awb"
                className="w-36 h-7 rounded-md shadow-sm  border border-slate-200 text-neutral-400 text-sm font-normal"
                value={filterData?.awb}
                onChange={(e) => onAwbChange(e)}
              />
            </div>
            <div className="flex text-xs text-gray-900 font-medium items-center gap-2">
              <label>Vessel</label>
              <div
                className="w-40 h-7 border-t rounded-lg border-slate-200"
                id="compo"
              >
                <ComboBox
                  data={vessels}
                  name="vessel"
                  dataItemKey="id"
                  textField="name"
                  className="w-36 h-7 border border-slate-200 rounded-md shadow-sm"
                  value={filterData?.vessel}
                  suggest={true}
                  clearButton={true}
                  filterable={true}
                  onFilterChange={filterList}
                  onChange={onVesselChange}
                />
              </div>
            </div>
            <div className="flex text-xs text-gray-900 font-medium items-center gap-2">
              <label>Customer</label>
              <div
                className="w-40 h-7 border-t rounded-lg border-slate-200"
                id="compo"
              >
                <ComboBox
                  data={filteredCustomers}
                  name="customer"
                  dataItemKey="id"
                  textField="name"
                  className="w-full h-full border border-slate-200 rounded-md shadow-sm"
                  value={filterData?.customer}
                  onFilterChange={filterList}
                  suggest={true}
                  clearButton={true}
                  onChange={onCustomerChange}
                />
              </div>
            </div>
            <button
              type="button"
              className="h-7 flex justify-center items-center bg-sky-500 rounded text-sm font font-medium text-white w-fit px-1"
              onClick={() => onJobFilter(filterData)}
              title="Search"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </button>
            <button
              type="button"
              className="h-7 bg-black rounded text-sm font font-medium text-white w-fit px-1"
              onClick={onResetClick}
              title="Reset"
            >
              <img src="reset-icon.svg" />
            </button>
            <button
              type="button"
              className="inline-flex h-7 w-fit px-2 items-center justify-center shadow-sm text-xs gap-1 font-semibold rounded text-white bg-lime-600 focus:outline-none  sm:order-1"
              onClick={() => navigate(`/job/new`)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  clipRule="evenodd"
                />
              </svg>
              Add New O-WH Job
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow relative overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {loading && <KendoLoader />}
              <ExcelExport data={jobs} ref={_export}>
                <Tooltip openDelay={300} position="top" anchorElement="target">
                  <Grid
                    data={process(jobs, defaultState)}
                    pageable={{
                      pageSizes: false,

                    }}
                    // filterable={true}
                    selectable={{
                      enabled: true,
                      drag: false,
                      cell: false,
                      mode: "multiple",
                    }}
                    reorderable={true}
                    //total={gri}
                    onDataStateChange={handleGridDataStateChange}
                    onRowDoubleClick={onEditClick}
                    onPageChange={onPageChange}
                    {...pageState}
                    total={filterData.total}
                    style={{
                      borderRadius: "10px",
                      borderColor: "#E5E7EB",
                      fontSize: "12px",
                      fontFamily: "poppins",
                      zIndex: 0,
                      height: "78vh",
                    }}
                  >
                    {stateColumns.map(
                      (column, idx) =>
                        column.show && (
                          <GridColumn
                            key={idx}
                            field={column.field}
                            title={column.title}
                            filterable={column.filterable}
                            cell={column.cell}
                            selectable={true}
                            width={column.width}
                            headerCell={column.ProductNameHeader}
                            columnMenu={(props) => (
                              <CustomColumnMenu
                                {...props}
                                columns={stateColumns}
                                onColumnsSubmit={onColumnsSubmit}
                              />
                            )}
                          />
                        )
                    )}
                  </Grid>
                </Tooltip>
                <JobLoader
                  dataState={pageState}
                  onDataReceived={dataReceived}
                  filterData={filterData}
                />
              </ExcelExport>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
