import React, { useRef, useEffect } from "react";

const AutoResizeTextarea = ({
  text,
  handleChange,
  name,
  poId,
  style,
  type,
  packageId,
}) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    // Auto-resize textarea height based on its content
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, []);

  const handleTextareaChange = (event) => {
    // Update textarea height as content changes
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
    if (type !== null) {
      handleChange(event, poId, type, packageId);
    } else {
      handleChange(event, poId, null, packageId);
    }
  };

  return (
    <textarea
      ref={textareaRef}
      onChange={handleTextareaChange}
      value={text}
      name={name}
      className={style}
      style={{
        resize: "none",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        maxHeight: "100%",
      }}
    />
  );
};

export default AutoResizeTextarea;
